exports.components = {
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tours-akali-jsx": () => import("./../../../src/pages/tours/akali.jsx" /* webpackChunkName: "component---src-pages-tours-akali-jsx" */),
  "component---src-pages-tours-bike-jsx": () => import("./../../../src/pages/tours/bike.jsx" /* webpackChunkName: "component---src-pages-tours-bike-jsx" */),
  "component---src-pages-tours-canopy-jsx": () => import("./../../../src/pages/tours/canopy.jsx" /* webpackChunkName: "component---src-pages-tours-canopy-jsx" */),
  "component---src-pages-tours-cooking-classes-jsx": () => import("./../../../src/pages/tours/cooking-classes.jsx" /* webpackChunkName: "component---src-pages-tours-cooking-classes-jsx" */),
  "component---src-pages-tours-diving-jsx": () => import("./../../../src/pages/tours/diving.jsx" /* webpackChunkName: "component---src-pages-tours-diving-jsx" */),
  "component---src-pages-tours-dolphins-jsx": () => import("./../../../src/pages/tours/dolphins.jsx" /* webpackChunkName: "component---src-pages-tours-dolphins-jsx" */),
  "component---src-pages-tours-fishing-jsx": () => import("./../../../src/pages/tours/fishing.jsx" /* webpackChunkName: "component---src-pages-tours-fishing-jsx" */),
  "component---src-pages-tours-hidden-beach-at-marietas-islands-jsx": () => import("./../../../src/pages/tours/hidden-beach-at-marietas-islands.jsx" /* webpackChunkName: "component---src-pages-tours-hidden-beach-at-marietas-islands-jsx" */),
  "component---src-pages-tours-horse-back-riding-jsx": () => import("./../../../src/pages/tours/horse-back-riding.jsx" /* webpackChunkName: "component---src-pages-tours-horse-back-riding-jsx" */),
  "component---src-pages-tours-jet-skies-tb-tours-jsx": () => import("./../../../src/pages/tours/jet-skies-tb-tours.jsx" /* webpackChunkName: "component---src-pages-tours-jet-skies-tb-tours-jsx" */),
  "component---src-pages-tours-marietas-jsx": () => import("./../../../src/pages/tours/marietas.jsx" /* webpackChunkName: "component---src-pages-tours-marietas-jsx" */),
  "component---src-pages-tours-marigalante-pirate-ship-jsx": () => import("./../../../src/pages/tours/marigalante-pirate-ship.jsx" /* webpackChunkName: "component---src-pages-tours-marigalante-pirate-ship-jsx" */),
  "component---src-pages-tours-night-jsx": () => import("./../../../src/pages/tours/night.jsx" /* webpackChunkName: "component---src-pages-tours-night-jsx" */),
  "component---src-pages-tours-teatro-vallarta-mayahuel-jsx": () => import("./../../../src/pages/tours/teatro-vallarta-mayahuel.jsx" /* webpackChunkName: "component---src-pages-tours-teatro-vallarta-mayahuel-jsx" */),
  "component---src-pages-tours-tequila-jsx": () => import("./../../../src/pages/tours/tequila.jsx" /* webpackChunkName: "component---src-pages-tours-tequila-jsx" */),
  "component---src-pages-tours-unique-atvs-rzrs-jsx": () => import("./../../../src/pages/tours/unique-atvs-&-rzrs.jsx" /* webpackChunkName: "component---src-pages-tours-unique-atvs-rzrs-jsx" */),
  "component---src-pages-tours-whales-jsx": () => import("./../../../src/pages/tours/whales.jsx" /* webpackChunkName: "component---src-pages-tours-whales-jsx" */),
  "component---src-pages-video-gallery-js": () => import("./../../../src/pages/video-gallery.js" /* webpackChunkName: "component---src-pages-video-gallery-js" */)
}

